/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      homepagePhotos: allFile(
        filter: { sourceInstanceName: { eq: "homepage" } }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `)

  data.homepagePhotos.nodes.sort((a, b) => {
    return (
      parseInt(a.childImageSharp.fluid.originalName, 10) -
      parseInt(b.childImageSharp.fluid.originalName, 10)
    )
  })

  return (
    <Layout>
      <SEO title="Hahn Wedding" />
      <div>
        <h1
          sx={{
            mt: 4,
            fontSize: [80, 92, 128],
            textAlign: 'center',
            fontFamily: '"mindline"',
            fontStyle: 'italic',
            lineHeight: 0.8,
            color: 'gray.800',
          }}
        >
          hannah + mason
        </h1>
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 4,
          }}
        >
          <p
            sx={{
              variant: 'text.body',
              mx: 3,
              display: 'flex',
              alignItems: 'center',
              textTransform: 'uppercase',
              letterSpacing: '.075em',
              fontSize: 1,
            }}
          >
            June 6th, 2020
          </p>
          <p
            sx={{
              variant: 'text.body',
              mx: 3,
              textTransform: 'uppercase',
              letterSpacing: '.075em',
              fontSize: 1,
            }}
          >
            los olivos, ca
          </p>
        </div>
        <div
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: '1px',
            bg: 'gray.400',
            p: '1px',
            maxWidth: 1200,
            mx: 'auto',
          }}
        >
          {data.homepagePhotos.nodes.map(
            ({ childImageSharp: { fluid: photo } }) => (
              <div
                sx={{
                  bg: 'white',
                  p: 1,
                }}
              >
                <Img fluid={photo} sx={{}} />
              </div>
            )
          )}
        </div>
        <div sx={{ my: [4, 5], mx: 'auto', maxWidth: '40em' }}>
          <h2 sx={{ variant: 'text.heading', fontSize: [24, 24, 32], mb: 3 }}>
            Our Story
          </h2>
          <p
            sx={{
              variant: 'text.body',
              mb: 3,
              fontSize: [16, 16, 18],
              lineHeight: 1.5,
            }}
          >
            <b>Mason and Hannah</b> went to pre-school together. They ended up
            at different elementary schools from 2nd-8th grade, but were later
            reunited at the same high school. Mason was a three-sport athlete
            while Hannah kept herself busy with ASB and choir. At the end of
            their senior year, Hannah sang the National Anthem at one of Mason's
            basketball games. A group of seniors went to Lamppost Pizza after
            the game to celebrate a few birthdays when Mason approached Hannah
            and said a cheesy one-liner about her singing voice. The next
            morning, Hannah saw him sleeping in his car before class and tapped
            on his window - they've talked every day since.
          </p>
          <p
            sx={{
              variant: 'text.body',
              mb: 3,
              fontSize: [16, 16, 18],
              lineHeight: 1.5,
            }}
          >
            Mason and Hannah started dating at the end of their senior year. On
            June 6th, 2010, after Hannah's high school graduation party, Mason
            asked Hannah to be his girlfriend. Well aware that he was going to
            Cal Poly San Luis Obispo and Hannah would be going to UC Irvine,
            they jumped into this whole relationship thing and never looked
            back. They did long distance for 4.5 years until Mason came back
            home after graduation. And here they are, about to be husband and
            wife on their 10 year dating anniversary, June 6th 2020!
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
